export enum OutputsPersistKey {
	'resetPasswordEndpoint',
	'userstatusEndpoint',
	'supportedCountries',
	"resendRegistrationEndpoint"
}

export type TSupportedCountry = {
	countryCode?: string
	labelDE?: string
	labelEN?: string
	zipRegex?: string
	iban?: boolean
	smsSupported?: boolean
	phoneSupported?: boolean
	bankRestricted?: boolean
}

export const setOutputsContent = (data: Record<string, string>) => {
	for (const key in OutputsPersistKey) {
		const rawValue = data[key]

		if (rawValue !== undefined) {
			window.sessionStorage.setItem(key, rawValue)
		}

		// if (rawValue) {
		// 	let value

		// 	switch (key) {
		// 		case 'supportedCountries':
		// 			value = convertSupportedCountries(rawValue)
		// 			break

		// 		default:
		// 			value = rawValue
		// 	}

		// 	if (value !== undefined) {
		// 		window.sessionStorage.setItem(key, value)
		// 	}
		// }
	}
}

export function getOutputsContentByKey<T = unknown>(key: keyof typeof OutputsPersistKey): T | undefined {
	const data = window.sessionStorage.getItem(key)

	if (data === null) {
		return undefined
	}

	try {
		return JSON.parse(data)
	} catch {
		return data as T
	}
}
