import { ENV_CUSTOM_NODE_ENV, ENV_ENVIRONMENT, ENV_TENANT } from 'environment'

type TConfig = {
	[key: string]: Record<string, TConfigKeys | Record<string, TConfigKeys>>
}

type TConfigKeys = {
	TENANT?: string
	GOOGLE_MAPS_API: string
	AUTO_LOGOUT_IN_MINUTES: number
	AUTO_TOKEN_REFRESH_IN_SECONDS: number
	I18N_SOURCE_LANGUAGE: string
	I18N_TARGET_LANGUAGES: string[]
	AGREEMENT_VERSION: string
	SHOW_FORCED_MIGRATION_MODAL?: boolean
	BLOCKED_REGISTRATION_EMAIL_DOMAINS?: string[]
	COOKIE_DOMAIN: string
	INSTALL_PROMPT_REMINDER_IN_DAYS?: number
	ENABLE_NEWSPAPERS: boolean
}

declare global {
	var __DECADIA__CONFIG__: TConfigKeys
}

const flattenConfig = (data: TConfig) =>
	Object.entries(data).reduce(
		(flattenedConfig: TConfigKeys, [key, entries]) => {
			if (key === 'shared') {
				flattenedConfig = {
					...flattenedConfig,
					...(entries as Partial<TConfigKeys>),
				}
			}

			if (key === ENV_TENANT) {
				const { shared, [ENV_ENVIRONMENT]: envConfig } = entries as Record<string, TConfigKeys>

				if (shared !== undefined) {
					flattenedConfig = {
						...flattenedConfig,
						...shared,
					}
				}

				if (envConfig !== undefined) {
					flattenedConfig = {
						...flattenedConfig,
						...envConfig,
					}
				}
			}

			return flattenedConfig
		},
		{ TENANT: ENV_TENANT } as TConfigKeys
	)

export const setGlobalConfig = (data: TConfig) => {
	let value: TConfigKeys

	if ('local' === ENV_CUSTOM_NODE_ENV) {
		value = flattenConfig(data)
	} else {
		value = data as unknown as TConfigKeys
	}

	Object.defineProperty(globalThis, '__DECADIA__CONFIG__', {
		value,
		enumerable: false,
		configurable: false,
		writable: false,
	})
}
