import { Amplify, Auth } from 'aws-amplify'
import { ENV_CUSTOM_NODE_ENV, ENV_ENVIRONMENT, ENV_TENANT } from 'environment'
import { setGlobalConfig } from 'helper/setGlobalConfig'
import { lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { setOutputsContent } from 'shared/helper/outputsContent'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const App = lazy(() => import('./views/App'))

const OUTPUTS_FILE_NAME =
	'local' === ENV_CUSTOM_NODE_ENV ? `outputs-${ENV_TENANT}-${ENV_ENVIRONMENT}.json` : 'outputs.json'

const initApp = async () => {
	const tenantConfig = await fetch(`/config.json`)
	const tenantConfigData = await tenantConfig.json()

	setGlobalConfig(tenantConfigData)
	const outputs = await fetch(`/${OUTPUTS_FILE_NAME}`)
	const outputsData = await outputs.json()

	const config = outputsData['move37-rentnerportal']
	setOutputsContent(config)

	Amplify.configure(config)

	Amplify.configure({
		API: {
			graphql_endpoint: config.graphqlEndpoint,
			graphql_headers: async () => {
				return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() }
			},
		},
		Auth: {
			authenticationFlowType: 'CUSTOM_AUTH',
		},
	})

	if ('local' !== ENV_CUSTOM_NODE_ENV) {
		Amplify.configure({
			Auth: {
				cookieStorage: {
					domain: window.location.host,
					expires: '', // this sets the cookie to a session cookie
					secure: true,
				},
			},
		})
	}

	const container = document.getElementById('root')
	if (!container) {
		return
	}

	const root = createRoot(container)
	root.render(<App />)
}

initApp()

serviceWorkerRegistration.register({
	onUpdate: async (registration) => {
		if (registration && registration.waiting) {
			await registration.unregister()
			window.postMessage({ appUpdateAvailable: true }, window.location.origin)
			registration.waiting.postMessage({ type: 'SKIP_WAITING' })
		}
	},
})
